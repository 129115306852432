import { Link } from "gatsby";
import styled from "styled-components"

const Root = styled(Link)`
  color: #fff;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-radius: 0.3rem;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize};
  text-decoration: none;
  display: flex;
  align-items: center;

  :hover {
    background-color: #ed4c00;
    cursor: pointer;
  }

  @media (max-width: 1100px) {
    :hover {
      background-color: transparent;
    }
  }
`;

export default Root;
