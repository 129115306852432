import React from "react"
import Drawer from "../ui/drawer";
import PricingForm from "../forms/pricingForm";

const Root = ({ open, handleClose }) => {
    return (
        <Drawer heading="Price Calculator" caption="Enter the number of people in your organisation to get an accurate, automated cost estimate." open={open} handleClose={handleClose}>
            {open && <PricingForm />}
        </Drawer>
    )
}

export default Root
