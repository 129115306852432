import React from "react"
import Drawer from "../ui/drawer";
import SignUpForm from "../forms/signUpForm";

const Root = ({ open, handleClose }) => {
    return (
        <Drawer heading="Sign Up" caption="Sign up and someone from our sales team will contact you shortly." open={open} handleClose={handleClose}>
            {open && <SignUpForm />}
        </Drawer>
    )
}

export default Root
