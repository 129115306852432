import React from "react"
import Drawer from "../ui/drawer";
import RequestDemoForm from "../forms/requestDemoForm";

const Root = ({ open, handleClose }) => {
    return (
        <Drawer heading="Request Demo" caption="We would love to hear from you. Enter your details below for an obligation free demo." open={open} handleClose={handleClose}>
            {open && <RequestDemoForm />}
        </Drawer>
    )
}

export default Root
