import React from "react"
import styled from "styled-components"
import PrimaryButton from "../ui/primaryButton";
import OutlineButton from "../ui/outlineButton";

const Root = styled.section`
  background: linear-gradient(0deg, #18232e 50%, #f9f9f9 50%);
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
    height: auto;
  }
`;

const Container = styled.div`
  background-color: #234161;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%232c2361' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 100%;
  max-width: 1160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-radius: 1rem;

  @media (max-width: 1100px) {
        margin: 0 1rem;
    }
`;

const Heading = styled.h3`
  color: #fff;
  font-size: 2rem;
  margin: 1rem;
  text-align: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin: 0 0 1rem;
`;

const GetStartedSection = ({ handleRequestDemoClick, handleGettingStartedClick }) => {
  return (
    <Root>
      <Container>
        <Heading>
          Ready to get started? <span role="img" aria-label="rocket">🚀</span>
        </Heading>
        <ButtonContainer>
          {/*<OutlineButton to="/sign-up" onClick={handleGettingStartedClick} padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Sign Up</OutlineButton>*/}
          <PrimaryButton to="/request-demo" onClick={handleRequestDemoClick} padding="0.8rem" margin="0.8rem 0 0.8rem 0.8rem" fontSize="1rem">Request Demo</PrimaryButton>
        </ButtonContainer>
      </Container>
    </Root>
  )

}

export default GetStartedSection
