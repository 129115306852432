import React, { useEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring';

const Root = styled(animated.div)`
    font-size: 1.1rem;
    color: #dbdbdb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
        border: solid #dbdbdb;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 12px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    @media (max-width: 1100px) {
        display: none;
    }
`;

const ScrollForMore = () => {
    const [spring, setSpring] = useSpring(() => {
        return {
            config: { tension: 350, friction: 30 },
            opacity: '1'
        }
    });


    useEffect(() => {
        const onScroll = (e) => {
            if (window.scrollY >= 300) {
                setSpring({ opacity: '0' })
            } else {
                setSpring({ opacity: '1' })
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [setSpring]);


    return (
        <Root style={spring}>
            <span>Scroll to Explore</span>
            <i></i>
        </Root>
    )
}

export default ScrollForMore
