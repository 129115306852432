import React, { useState } from 'react';
import { Formik } from 'formik'
import FormContainer from '../ui/formContainer'
import InputContainer from '../ui/inputContainer'
import Input from '../ui/input'
import SubmitButton from '../ui/submitButton'
import Form from '../ui/form'
import SuccessPrompt from '../ui/successPrompt'
import ErrorPrompt from '../ui/errorPrompt'

const initialValues = {
    name: "",
    emailAddress: "",
    phone: "",
    companyName: ""
};

const Root = () => {
    const [formState, setFormState] = useState("initial");

    const onSubmit = async (values, formikBag) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'click', {
                event_category: 'form_submit',
                event_label: 'demo'
            });
        }

        const response = await fetch('https://api.beyondattendance.com/api/landing/demo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: values.name,
                emailAddress: values.emailAddress,
                phone: values.phone,
                companyName: values.companyName
            }),
        })

        if (response.ok && response.status === 200) {
            setFormState("success");
        } else {
            setFormState("error");
        }

        formikBag.resetForm();
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <FormContainer>
                        <InputContainer>
                            <label htmlFor="request-demo-name">Name <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={isSubmitting} required id="request-demo-name" type="text" value={values.name} onChange={event => setFieldValue("name", event.target.value)} />
                        </InputContainer>

                        <InputContainer>
                            <label htmlFor="request-demo-email">Email <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={isSubmitting} required id="request-demo-email" type="email" value={values.emailAddress} onChange={event => setFieldValue("emailAddress", event.target.value)} />
                        </InputContainer>

                        <InputContainer>
                            <label htmlFor="request-demo-phone">Phone <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={isSubmitting} required id="request-demo-phone" type="tel" value={values.phone} onChange={event => setFieldValue("phone", event.target.value)} />
                        </InputContainer>

                        <InputContainer>
                            <label htmlFor="request-demo-company">Company</label>
                            <Input disabled={isSubmitting} id="request-demo-company" type="text" value={values.companyName} onChange={event => setFieldValue("companyName", event.target.value)} />
                        </InputContainer>
                    </FormContainer>
                    {formState === "success" && <SuccessPrompt><span role="img" aria-label="Success">✅</span> We have received your request and will be in touch soon.</SuccessPrompt>}
                    {formState === "error" && <ErrorPrompt><span role="img" aria-label="Error">❌</span> An error occurred while sending your request. Please try again.</ErrorPrompt>}
                    <SubmitButton disabled={isSubmitting} type="submit">Request</SubmitButton>
                </Form>
            )}
        </Formik>
    )
}

export default Root;
