import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Root = styled.h2`
    color: #fff;
    font-size: 3rem;
    margin: 1rem 0;
    line-height: 4rem;
    overflow: hidden;

    @media (max-width: 1100px) {
        font-size: 2rem;
        text-align: center;
        line-height: 2.5rem;
    }
`

const Cursor = styled.span`
    animation: blink 0.7s infinite;
    opacity: 1;

    @keyframes blink {
        0% {
            opacity:1
        }
        
        50% {
            opacity:0
        }
        
        100% {
            opacity:1
        }
    }
`;

const Statement = styled.span`
    @media (min-width: 1100px) {
        white-space: nowrap
    }
`;

const Autotype = ({ statement, punchlines }) => {
    const [questionIndex, setQuestionIndex] = useState(1);
    const [question, setQuestion] = useState(punchlines[0]);
    const [showCursor, setShowCursor] = useState(false);

    useEffect(() => {
        const handles = [];

        const type = (pieces, concat) => {
            if (!pieces.length) {
                handles.push(setTimeout(() => {
                    if (questionIndex === punchlines.length - 1) {
                        setQuestionIndex(0);
                    } else {
                        setQuestionIndex(questionIndex + 1);
                    }
                }, 3000))

                return;
            }

            const newLetter = pieces.shift();
            const newValue = concat + newLetter;

            setQuestion(newValue);
            handles.push(setTimeout(() => {
                type(pieces, newValue);
            }, Math.floor(Math.random() * 150) + 100));
        };

        const erase = (pieces, onComplete) => {
            if (!pieces.length) {
                handles.push(setTimeout(() => {
                    onComplete();
                }, 150));
                return;
            }

            pieces.pop();

            const newValue = pieces.concat();

            setQuestion(newValue);
            handles.push(setTimeout(() => {
                erase(pieces, onComplete)
            }, 50))
        };

        const question = punchlines[questionIndex];
        const questionPieces = question.split("");

        const previousQuestionIndex = questionIndex === 0 ? punchlines.length - 1 : questionIndex - 1;
        const previousQuestion = punchlines[previousQuestionIndex];
        const previousQuestionPieces = previousQuestion.split("");

        const startTyping = () => {
            handles.push(setTimeout(() => {
                setShowCursor(true);
                erase(previousQuestionPieces, () => type(questionPieces, ""));
            }, 2000));
        }

        if (window.innerWidth > 1100) {
            if ('requestIdleCallback' in window) {
                requestIdleCallback(startTyping)
            } else {
                startTyping();
            }
        }

        return () => { handles.forEach(handle => clearTimeout(handle)) };

    }, [punchlines, questionIndex]);

    return (
        <Root>
            <Statement>{statement}</Statement><br /><span>{question}</span>{showCursor && <Cursor>|</Cursor>}
        </Root>
    )

}

export default Autotype
