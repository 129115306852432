import React from "react"
import styled from "styled-components"

const Root = styled.div`
  height: 14rem;
  background-color: #efefefef;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
        height: auto;
    }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Heading = styled.h3`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
  color: #696969;
  margin: 1rem 0;
  font-weight: 300;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-flow: wrap;
`;

const Logo = styled.img`
    height: 2.5rem;
    filter: grayscale(1) opacity(0.3);
    margin: 0 1rem;

    @media (max-width: 1100px) {
        height: 2rem;
        margin: 1rem 0.5rem;
    }
`;

const TrustSection = () => {

  return (
    <Root>
      <Container>
        <Heading>Trusted by many leading brands</Heading>
        <LogoContainer>
          <Logo src="../images/customers/jetmaster.png" alt="Jet Master" />
          <Logo src="../images/customers/labora.png" alt="Labora" />
          <Logo src="../images/customers/lisa-cars.png" alt="Lisa Cars" />
          <Logo src="../images/customers/pnp.png" alt="PnP" />
          <Logo src="../images/customers/power-fashion.png" alt="Power Fashion" />
          <Logo src="../images/customers/sheq-safety.png" alt="Sheq Safety" />
          <Logo src="../images/customers/span-africa.png" alt="Span Africa" />
          <Logo src="../images/customers/twr.png" alt="TWR" />
          <Logo src="../images/customers/xquisite.png" alt="Xquisite" />
          <Logo src="../images/customers/total.png" alt="Total" />
          <Logo src="../images/customers/bp.png" alt="BP" />
          <Logo src="../images/customers/caltex.png" alt="Caltex" />
          <Logo src="../images/customers/froggie.png" alt="Froggie" />
          <Logo src="../images/customers/levis.png" alt="Levis" />
          <Logo src="../images/customers/cash-crusaders.png" alt="Cash Crusaders" />
        </LogoContainer>
      </Container>
    </Root>
  )

}

export default TrustSection
