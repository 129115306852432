import styled from "styled-components"

const Root = styled.div`
    width: 100%;
    text-align: center;
    background-color: #d34400;
    color: #fff;
    min-height: 3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    p {
        margin: 0;
        font-size: 1rem;
    }

    a {
        color: #fff;
    }
`;

export default Root;
