import React from "react"
import styled from "styled-components"

const Root = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  box-sizing: border-box;

  @media (max-width: 1100px) {
    margin: 1rem 0;
    padding: 0 1rem;
    }
`;

const Container = styled.div`
    background-color: #f0f0f0;
    border-radius: 1rem;
    width: 100%;
    max-width: 1160px;
    text-align: center;
    font-size: 1.2rem;
    color: #696969;
    margin: 1rem 0;
    font-weight: 300;
    padding: 1rem;

    a {
    color: #222222;

    :hover{
      color: #000000;
    }
  }
`;

const PageBreak = ({ children }) => {
    return (
        <Root>
            <Container>
                {children}
            </Container>
        </Root>
    )
}

export default PageBreak

