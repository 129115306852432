import React from "react"
import styled from "styled-components"

const Root = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1100px) {
    margin: 1rem 0;
    }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;

    @media (max-width: 1100px) {
        padding: 0 1rem;
    }
`;

const Heading = styled.h1`
    font-size: 2.5rem;
    color: #18232e;
    margin: 2rem 0 0;
    text-align: center;
`;

const HeadingGroup = ({ heading, children }) => {
    return (
        <Root>
            <Container>
                <Heading>{heading}</Heading>
                <div>
                    {children}
                </div>
            </Container>
        </Root>
    )
}

export default HeadingGroup
