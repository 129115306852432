import { Link } from "gatsby";
import styled from "styled-components"

const Root = styled(Link)`
  background-color: #d34400;
  color: #fff;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-radius: 0.3rem;
  font-weight: 600;
  font-size: ${({ fontSize }) => fontSize};
  text-decoration: none;
  display: flex;
  align-items: center;
  
  :hover {
    background-color: #ff5200;
    cursor: pointer;
  }
`; 

export default Root;
