import React, { useState } from 'react';
import { Formik } from 'formik'
import FormContainer from '../ui/formContainer'
import InputContainer from '../ui/inputContainer'
import Input from '../ui/input'
import Checkbox from '../ui/checkbox'
import SubmitButton from '../ui/submitButton'
import Form from '../ui/form'
import SuccessPrompt from '../ui/successPrompt'
import ErrorPrompt from '../ui/errorPrompt'
import { Link } from "gatsby";

const initialValues = {
    emailAddress: '',
    peopleCount: '',
    includeRemoteCheckin: false,
    companyName: ''
};

const Root = ({ reset }) => {
    const [formState, setFormState] = useState("initial");
    const [priceResponse, setPriceResponse] = useState(null);

    reset = () => {
        setFormState("initial");
    };

    const onSubmit = async (values, formikBag) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'click', {
                event_category: 'form_submit',
                event_label: 'price_calculator'
            });
        }

        setFormState("initial");

        const response = await fetch('https://api.beyondattendance.com/api/landing/pricing', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                emailAddress: values.emailAddress,
                peopleCount: +values.peopleCount,
                includeRemoteCheckin: values.includeRemoteCheckin,
                companyName: values.companyName
            }),
        })

        if (response.ok && response.status === 200) {
            const responseObject = await response.json();
            setPriceResponse(responseObject);
            setFormState("success");
        } else {
            setFormState("error");
        }

        formikBag.resetForm();
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
                <Form
                    onSubmit={handleSubmit}
                >
                    <FormContainer>
                        <InputContainer>
                            <label htmlFor="price-calculator-email">Email <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={isSubmitting} required id="price-calculator-email" type="email" value={values.emailAddress} onChange={event => setFieldValue("emailAddress", event.target.value)} />
                        </InputContainer>

                        <InputContainer>
                            <label htmlFor="price-calculator-people">Number of People <span style={{ color: 'red' }}>*</span></label>
                            <Input disabled={isSubmitting} required id="price-calculator-people" type="number" value={values.peopleCount} onChange={event => setFieldValue("peopleCount", event.target.value)} />
                        </InputContainer>

                        <InputContainer>
                            <label htmlFor="price-calculator-company">Company</label>
                            <Input disabled={isSubmitting} id="price-calculator-company" type="text" value={values.companyName} onChange={event => setFieldValue("companyName", event.target.value)} />
                        </InputContainer>

                        <InputContainer>
                            <label htmlFor="price-calculator-include-checkin">
                                <Checkbox disabled={isSubmitting} id="price-calculator-include-checkin" type="checkbox" checked={values.includeRemoteCheckin} onChange={() => setFieldValue("includeRemoteCheckin", !values.includeRemoteCheckin)} />
                                <span>Include Remote Checkin</span>
                            </label>
                        </InputContainer>
                    </FormContainer>
                    {formState === "success" && priceResponse && priceResponse.price > 0 && <SuccessPrompt><span role="img" aria-label="Success">💵</span> Subscription cost is <strong>R{priceResponse.price.toFixed(2)} per month</strong>.</SuccessPrompt>}
                    {formState === "success" && priceResponse && priceResponse.price < 0 && <SuccessPrompt><span role="img" aria-label="Success">💵</span> Please <Link to="/contact-us">contact us</Link> for an estimate.</SuccessPrompt>}
                    {formState === "error" && <ErrorPrompt><span role="img" aria-label="Error">❌</span> An error occurred while calculating your price. Please try again.</ErrorPrompt>}
                    <SubmitButton disabled={isSubmitting} type="submit">Calculate</SubmitButton>
                </Form>
            )}
        </Formik>
    )
}

export default Root;
