import React from "react"
import styled from "styled-components"
import PrimaryButton from "./ui/primaryButton";
import OutlineButton from "./ui/outlineButton";
import Autotype from "./autotype";
import ScrollForMore from "./ui/scrollForMore";

const Root = styled.section`
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(35,65,97,1) 50%, rgba(68,134,204,1) 100%);
    min-height: calc(100vh - 7rem);

    @media (max-width: 1100px) {
        background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(35,65,97,1) 50%, rgba(68,134,204,1) 100%);
    }
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 7rem);
    background: radial-gradient(circle at right 100%, rgb(2 0 36 / 0%) 0%, rgb(35, 65, 97) 50%, rgb(255 255 255 / 0%) 100%);

    @media (max-width: 1100px) {
        flex-direction: column;
        flex: 1;
    }
`

const ContainerPrompt = styled.div`
    flex: 50%;
    display: flex;
    justify-content: center;
    padding: 3rem 0 3rem 3rem;
    flex-direction: column;

    @media (max-width: 1100px) {
        padding: 1rem;
    }
`

const ContainerImage = styled.div`
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    overflow: hidden;

    @media (max-width: 1100px) {
        padding: 1rem;
        justify-content: center;
    }
`

const HeroImg = styled.img`
    width: 100%;
    z-index: 1;
    max-width: 800px;

    @media (max-width: 1100px) {
        width: 100%;
        max-width: 600px;
    }
`;

const HeroSubtext = styled.h2`
    color: #dbdbdb;
    font-size: 1.3rem;
    margin: 1rem 0;
    font-weight: 300;
    line-height: 1.8rem;

    @media (max-width: 1100px) {
        text-align: center;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    margin: 1rem 0;

    @media (max-width: 1100px) {
        justify-content: center;
    }
`;

const Floor = styled.div`
    position: absolute;
    bottom: 0;
    background-color: #18232e;
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FloorContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 50%;
`

const FloorSpacer = styled.div`
    height: 6rem;
    width: 100%;

    @media (max-width: 1100px) {
        display: none;
    }
`;

const Hero = ({ statement, punchlines, caption, handleRequestDemoClick, handleGettingStartedClick }) => {
    return (
        <Root>
            <Container>
                <FloorContainer>
                    <ContainerPrompt>
                        <Autotype statement={statement} punchlines={punchlines} />
                        <HeroSubtext>
                            {caption}
                        </HeroSubtext>
                        <HeroSubtext>
                            It cannot get simpler.
                        </HeroSubtext>
                        <ButtonContainer>
                            <OutlineButton to="/contact-us" padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Contact Us</OutlineButton>
                            <PrimaryButton to="/request-demo" onClick={handleRequestDemoClick} padding="0.8rem" margin="0.8rem 0 0.8rem 0.8rem" fontSize="1rem">Request Demo</PrimaryButton>
                        </ButtonContainer>
                    </ContainerPrompt>
                    <FloorSpacer />
                </FloorContainer>

                <ContainerImage>
                    <HeroImg alt="Hero" src="../images/isometric-hero.png" />
                </ContainerImage>
                <Floor>
                    <ScrollForMore />
                </Floor>
            </Container>
        </Root>
    )
}

export default Hero
