import React, { useEffect } from "react"
import styled from "styled-components"
import { useSpring, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { useDrag } from "react-use-gesture";

const Root = styled(animated.div)`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 3;
    overflow: hidden;
    border-radius: 25px 25px 0 0;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 1rem;
    align-items: center;
`;

const Overlay = styled.div`
    background-color: #000;
    opacity: 0.5;
    z-index: 2;
    position: fixed;
    top: 0;
    bottom: 0%;
    width: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
`;

const Heading = styled.h3`
    font-size: 1.5rem;
    margin: 0;
`;

const Caption = styled.p`
    margin: 1rem 1rem 0 1rem;
    text-align: center;
`;

const Handle = styled.div`
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HandleBar = styled.div`
    height: 0.3rem;
    border-radius: 0.3rem;
    background-color: #d7d7d7;
    width: 4rem;
`;

const ContentContainer = styled.div`
    margin: 1rem;
`;

const Drawer = ({ children, open, handleClose, heading, caption }) => {
    const [ref, { height }] = useMeasure();
    const prevent = (e) => e.preventDefault();

    const [spring, setSpring] = useSpring(() => {
        return {
            config: { tension: 350, friction: 30, clamp: !open },
            height: '0px'
        }
    });

    useEffect(() => {
        if (open) {
            document.addEventListener('touchmove', prevent, { passive: false });
            setSpring({
                height: `${height}px`
            })
        } else {
            setSpring({
                height: `0px`
            })
        }

        return () => {
            document.removeEventListener('touchmove', prevent, { passive: false });
        }
    }, [open, height, setSpring])

    const handleDrag = (e) => {
        const {
            dragging,
            movement: [, deltaY]
        } = e;

        if (dragging) {
            setSpring({
                height: `${height - deltaY}px`
            })
        } else {
            if (deltaY > height / 2) {
                handleClose();
            } else {
                setSpring({
                    height: `${height}px`
                })
            }
        }
    };

    const bind = useDrag(handleDrag);

    return (
        <>
            <Root style={spring}>
                <Content ref={ref} {...bind()}>
                    <Handle>
                        <HandleBar />
                    </Handle>
                    <Header>
                        <Heading>{heading}</Heading>
                    </Header>
                    <Caption>{caption}</Caption>
                    <ContentContainer>
                        {children}
                    </ContentContainer>
                </Content>
            </Root>
            {open && <Overlay onClick={handleClose} />}
        </>

    )
}

export default Drawer
